<template>
  <base-page-item>
    <v-card class="pa-16 ma-16">
      <v-row>
        <v-col cols="6">
          <v-row>
            <v-col>
              <div class="font-weight-bold">
                _id:
              </div>
              <div class="font-weight-bold">
                api_key:
              </div>
              <div class="font-weight-bold">
                created_at:
              </div>
              <div class="font-weight-bold">
                updated_at:
              </div>
            </v-col>
            <v-col cols="8">
              <div>{{ apollo._id }}</div>
              <div>{{ apollo.api_key }}</div>
              <div>{{ apollo.created_at }}</div>
              <div>{{ apollo.updated_at | fromNow }}</div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col>
              <div class="font-weight-bold">
                daily_left:
              </div>
              <div class="font-weight-bold">
                hourly_left:
              </div>
              <div class="font-weight-bold">
                minute_left:
              </div>
            </v-col>
            <v-col>
              <div>{{ apollo.daily_left }}</div>
              <div>{{ apollo.hourly_left }}</div>
              <div>{{ apollo.minute_left }}</div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col>
              <div class="font-weight-bold">
                daily_limit:
              </div>
              <div class="font-weight-bold">
                hourly_limit:
              </div>
              <div class="font-weight-bold">
                minute_limit:
              </div>
            </v-col>
            <v-col>
              <div>{{ apollo.daily_limit }}</div>
              <div>{{ apollo.hourly_limit }}</div>
              <div>{{ apollo.minute_limit }}</div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </base-page-item>
</template>

<script>
  import BasePageItem from '@/components/base/BasePageItem';
  import { fromNowFilterMixin } from '@/mixins/fromNowFilterMixin';

  export default {
    name: 'ApolloItem',
    components: {
      BasePageItem,
    },
    mixins: [fromNowFilterMixin],
    props: {
      apollo: {
        type: Object,
        default: () => {},
      },
    },
  };
</script>

<style scoped></style>
